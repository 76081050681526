/*
 * Third party
 */


/*
 * Customm
 */

document.addEventListener("DOMContentLoaded", function() {
	const hamMenu = document.querySelector('.ham-menu');
	const navMenu = document.querySelector('.nav');
	const accMenu = document.querySelector('.account');
	const body = document.body;

	hamMenu.addEventListener('click', function() {
			hamMenu.classList.toggle('open');
			navMenu.classList.toggle('open');
			accMenu.classList.toggle('open');
			body.classList.toggle('no-scroll');
	});
});